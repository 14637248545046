export const useAppStore = defineStore("app", {
  state: () => ({
    forceReload: false,
    isMobile: false,
    isTablet: false,
  }),

  actions: {
    setForceReload(bool) {
      this.forceReload = bool;
    },
    setIsMobile(bool) {
      this.isMobile = bool;
    },
    setIsTablet(bool) {
      this.isTablet = bool;
    },
  },

  getters: {
    getForceReload(state) {
      return state.forceReload;
    },
  },
  persist: true,
});